/**
 * Redux Actions
 * @module AdminPortal/Redux/actions
 */
import { CART_ADD, CART_REMOVE } from './action-types';
export const cartAdd = product => ({
	type: CART_ADD,
	payload: product
});
export const cartRemove = product => ({
	type: CART_REMOVE,
	payload: product
});
