import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.scss';
import App from './App';
import Config from './config';
import * as serviceWorker from './serviceWorker';
import store from './redux/store';

ReactDOM.render(
	<Provider store={ store }>
		<App />
	</Provider>,
	document.getElementById('root')
);
// To do store.getState() in console, uncomment this
window.store = store;

// Save state to local storage
window.onbeforeunload = () => {
	console.log('onbeforeunload');
	const state = store.getState();
	localStorage.setItem(
		`${ Config.appId }-state`,
		JSON.stringify(state)
	);
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
