import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cartAdd, cartRemove } from './redux/actions';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.scss';
import { brown } from '@material-ui/core/colors';
import { createTheme, withStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { CircularProgress, Container, Paper } from '@material-ui/core';
// import Tabletop from 'tabletop';
import { Cart, Home, Product as ProductPage, Products as ProductsScreen, Category, Terms, Visit } from './components/screens';
import { AppBar, Hero /* , ProductsGrid */ } from './components/widgets';
import Product from './models/Product';
// import Config from './config';

const cacheData = require('./data');
// import { randomElements } from './includes/lib';

const mapStateToProps = state => ({
	cart: state.cart
});
const mapDispatchToProps = dispatch => ({
	cartAdd: product => dispatch(cartAdd(product)),
	cartRemove: product => dispatch(cartRemove(product))
});

const teal = '#003d3a';
// const color2 = '#795548';
// const headerFont1 = '\'Ewert\', cursive'; //"'Lora', serif"
// const headerFont2 = "'Lora', serif";
const headerFont3 = '\'Glass Antiqua\', cursive'; //"'Lora', serif"
const theme = createTheme({
	palette: {
		background: {
			paper: '#0000007F'
		},
		primary: {
			main: teal
		},
		secondary: {
			main: brown[500]
		},
		type: 'dark'
	},
	shape: {
		borderRadius: 4
	},
	// shadows: [],
	typography: {
		h2: {
			fontFamily: headerFont3,
			fontSize: '2em'
		},
		h3: {
			fontFamily: headerFont3,
			fontSize: '1.6em'
		},
		h4: {
			fontFamily: headerFont3,
			fontSize: '1.3em'
		},
		h5: {
			fontFamily: headerFont3,
			fontSize: '1.2em',
			textTransform: 'uppercase'
		},
		h6: {
			fontFamily: headerFont3,
			fontSize: '1em'
		}
	}
});
const styles = theme => ({
	grow: {
		flexGrow: 1,
	},
	paper: {
		borderRadius: theme.spacing(2),
		marginTop: theme.spacing(18),
		padding: theme.spacing(2, 2, 2, 2)
	}
});

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			anchorEl: null,
			data: null,
			filter: null
		};
		this.loadData = this.loadData.bind(this);
		// console.log(process.env);
	}
	componentDidMount() {
		this.loadData();
	}
	loadData() {

		fetch('https://www.arapuniart.co.nz/shop/feed').then(r => r.json().then(json => {
			const data = json.map(product => new Product(Object.assign(product, {
				isNew: product.ID >= cacheData.length - 10,
				isSold: product.Sold === 'Yes',
			}))).sort((a, b) => a.isNew && !b.isNew ? -1 : 1);
			this.setState({
				data
			});
		}));
		return;
		// const filter = process.env.NODE_ENV === 'development' ? [ 'Yes', 'dev' ] : [ 'Yes' ];
		// const data = cacheData.filter(item => filter.includes(item.Live)).map(product => new Product(Object.assign(product, {
		// 	isNew: product.ID >= cacheData.length - 10
		// }))).sort((a, b) => a.isNew && !b.isNew ? -1 : 1);
		// this.setState({
		// 	data
		// });
		// Tabletop.init({
		// 	key: Config.google.sheetsId,
		// 	callback: (googleData) => {
		// 		const data = googleData.filter(item => filter.includes(item.Live)).map(product => new Product(Object.assign(product, {
		// 			isNew: product.ID >= googleData.length - 10
		// 		}))).sort((a, b) => a.isNew && !b.isNew ? -1 : 1);
		// 		this.setState({
		// 			data
		// 		});
		// 		if(process.env.NODE_ENV !== 'development') setTimeout(this.loadData, 300000);
		// 	},
		// 	simpleSheet: true
		// });
	}
	render() {
		const { props: { classes }, state: { data, filter } } = this;
		const getData = () => filter ? data.filter(item => item.mainCategory === filter): data;
		// Dirty hack for scroll to top onRouteChanged
		const ScrollToTop = () => {
			// console.log(this.props);
			// window.scrollTo(0, 0);
			return null;
		};
		return (
			<ThemeProvider theme={theme}>
				<Router>
					<Route component={ ScrollToTop } />
					<AppBar data={ data } />
					{ data && <Hero data={ data.map(({ Image: image, Name: title }) => ({ image, title })).sort(() => 0.5 - Math.random()).slice(0, 5) } /> }
					<Container maxWidth="lg">
						<Paper className={ classes.paper }>
							{ data === null && <CircularProgress /> }
							{ /* data && <ProductsGrid cellHeight="300" data={ randomElements(data, 8).map(({ name, image }, i) => ({
								name, image, cols: i % 3 && i !== 7 ? 2 : 1
							})) } /> */ }
							{ data && (
								<Switch>
									<Route exact path="/products" component={ () => <ProductsScreen data={ data } /> } />
									<Route exact path="/visit" component={ Visit } />
									<Route exact path="/terms" component={ Terms } />
									<Route exact path="/cart" component={ () => <Cart products={ data } /> } />
									{ data.map((product) => <Route key={ product.slug } exact path={`/product/${ product.slug }`} component={ () => <ProductPage data={ product } /> } />) }
									{ [...new Set(data.map(i => i.mainCategory))].map(category => <Route key={ category.slug } exact path={`/category/${ category.slug }`} component={ () => <Category category={ category } data={ data.filter(p => p.mainCategory.slug === category.slug) } /> } />) }
									<Route component={ () => <Home products={ getData() } /> } />
								</Switch>
							) }
						</Paper>
					</Container>
				</Router>
			</ThemeProvider>
		);
	}
}
App.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(App));
