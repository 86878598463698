import React from 'react';
import { Button  } from '@material-ui/core';
import { AddShoppingCart, RemoveShoppingCart } from '@material-ui/icons';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { cartAdd, cartRemove } from '../../redux/actions';
const mapStateToProps = state => ({
	cart: state.cart
});
const mapDispatchToProps = dispatch => ({
	cartAdd: product => dispatch(cartAdd(product)),
	cartRemove: product => dispatch(cartRemove(product))
});
const useStyles = makeStyles(theme => ({
	rightIcon: {
		marginLeft: theme.spacing(1),
	}
}));

function ProductButton(props) {
	const classes = useStyles();
	const { cartAdd, cartRemove, cart, data } = props;
	const inCart = cart && cart.filter(p => p === data.id).length > 0;
	const buyLabel = inCart ? 'Remove from Cart' : 'Add to Cart';
	const buyShortLabel = inCart ? 'Remove' : 'Add';
	return (
		<>
			<Button title={ buyLabel } disabled={ data.isSold } variant="contained" color={ inCart ? 'default' : 'primary' } onClick={ () => inCart ? cartRemove(data.id) : cartAdd(data.id) } target="_blank">
				{ data.isSold ? 'Sold' : buyShortLabel }
				{ !data.isSold && inCart && <RemoveShoppingCart className={ classes.rightIcon } /> }
				{ !data.isSold && !inCart && <AddShoppingCart className={ classes.rightIcon } /> }
			</Button>
		</>
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductButton);
