import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Divider, Grid, Typography  } from '@material-ui/core';
import { ArrowBack as BackIcon } from '@material-ui/icons';
import { Helmet } from 'react-helmet';
import { Products as ProductsWidget } from '../widgets';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	title: {
		marginBottom: theme.spacing(2)
	},
	label: {
		display: 'inline-block',
		padding: theme.spacing(1),
		textTransform: 'uppercase'
	},
	leftIcon: {
		marginRight: theme.spacing(1)
	},
	rightIcon: {
		marginLeft: theme.spacing(1)
	},
}));

function Products(props) {
	const classes = useStyles();
	const { data, history } = props;
	return (
		<>
			<Helmet>
				<title>All Products :: Somewhere in Time</title>
				<meta name="description" content="All products on Somewhere in Time" />
			</Helmet>
			<Grid container className={ classes.section }>
				<Grid item xs>
					<Typography className={ classes.title } variant="h2">All Products ({ data.length })</Typography>
				</Grid>
				<Grid item>
					<Button aria-label="more" onClick={ () => history.goBack() }>
						<BackIcon className={ classes.leftIcon } />
						Back
					</Button>
				</Grid>
			</Grid>
			<ProductsWidget data={ data } />
			<Divider />
		</>
	);
}

export default withRouter(Products);
