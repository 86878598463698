import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Divider, Grid, Typography  } from '@material-ui/core';
import { ArrowBack as BackIcon } from '@material-ui/icons';
import { Helmet } from 'react-helmet';
import { Products } from '../widgets';
// import {
// 	FacebookShareButton, FacebookIcon,
// 	EmailShareButton, EmailIcon,
// } from 'react-share';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	title: {
		marginBottom: theme.spacing(2)
	},
	label: {
		display: 'inline-block',
		padding: theme.spacing(1),
		textTransform: 'uppercase'
	},
	leftIcon: {
		marginRight: theme.spacing(1)
	},
	rightIcon: {
		marginLeft: theme.spacing(1)
	},
}));

function Category(props) {
	const classes = useStyles();
	const { category, data, history } = props;
	// const link = `/category/${ escape(category.replace(' ', '-')).toLowerCase() }`;
	// const shareLink = `${ window.location.href }${ link }`;
	return (
		<>
			<Helmet>
				<title>{ category.name } :: Somewhere in Time</title>
				<meta name="description" content={ category.name } />
			</Helmet>
			<Grid container className={ classes.section }>
				<Grid item xs>
					<Typography className={ classes.title } variant="h2">{ category.name }</Typography>
				</Grid>
				<Grid item>
					<Button aria-label="more" onClick={ () => history.goBack() }>
						<BackIcon className={ classes.leftIcon } />
						Back
					</Button>
				</Grid>
			</Grid>
			<Products data={ data } />
			<Divider />
		</>
	);
}

export default withRouter(Category);
