import React, { useState } from 'react';
import { CircularProgress } from '@material-ui/core';

function ImageComponent (props) {
	const { src, className } = props;
	const [loaded, setLoaded] = useState(false);
	if (!loaded) {
		const im = new Image();
		im.onload = () => {
			setLoaded(true);
		};
		im.src = src;
	}
	return (
		<div>
			{ !loaded && <CircularProgress style={ { display: 'block', margin: '8px auto' } }/> }
			{ loaded && <div style={{
				background: `url(${ src }) no-repeat 0 50%`,
				backgroundSize: 'cover'
			}} className={ className }><img alt="" src={ src } style={ { maxWidth: '100%', visibility: 'hidden' } } /></div> }
		</div>
	);
}
export default ImageComponent;
