import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, CircularProgress, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import { ArrowBack as BackIcon, AspectRatio } from '@material-ui/icons';
import { ImageComponent, ProductButton } from '../widgets';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	label: {
		display: 'inline-block',
		padding: theme.spacing(1),
		textTransform: 'uppercase'
	},
	leftIcon: {
		marginRight: theme.spacing(1)
	},
	section: {
		margin: theme.spacing(3, 0)
	},
	title: {
		// fontFamily: "'Lora', serif",
		fontSize: '2.5em'
	},
	price: {
		fontSize: '3em'
	},
	priceMinor: {
		fontSize: '0.5em'
	}
}));

function Product(props) {
	const classes = useStyles();
	const { data, history } = props;
	if(!data) return <CircularProgress />;
	return (
		<>
			<Helmet>
				<title>{ data.name } :: { data.mainCategory.name } :: Somewhere in Time</title>
				<meta name="description" content={ `${ data.mainCategory.name }: ${ data.description }` } />
				<script type="application/ld+json">{ JSON.stringify(data.structuredData) }</script>
			</Helmet>
			<Grid container spacing={ 2 }>
				<Grid item md={ 4 }>
					<ImageComponent src={ data.image }  alt={ data.name } className={ classes.media } />
				</Grid>
				<Grid item md={ 8 }>
					<Grid container spacing={ 2 }>
						<Grid item md={ 8 }>
							<Typography variant="h2" className={ classes.title }>{ data.name }{ data.isNew ? ' New*' : '' }</Typography>
						</Grid>
						<Grid item md={ 4 } align="right">
							<Typography variant="h3" className={ classes.price }>
								{ data.isSold ? 'Sold' : <>${ (data.price + '').split('.')[0] }<span className={classes.priceMinor }>.{ (((data.price + '').split('.')[1] || 0) + '').padEnd(2, '0') }</span></> }
							</Typography>
						</Grid>
						<Grid item xs={ 12 }>
							<ProductButton data={ data } />
							<Typography className={ classes.section }>{ data.description }</Typography>
							<List>
								<ListItem title="Dimensions">
									<ListItemIcon>
										<AspectRatio />
									</ListItemIcon>
									<ListItemText>{ data.height }{ data.width && data.height && ' x ' }{ data.width }</ListItemText>
								</ListItem>
							</List>
							<Divider />
							<Grid container className={ classes.section }>
								<Grid item xs>
									<Button aria-label="more" onClick={ () => history.goBack() }>
										<BackIcon className={ classes.leftIcon } />
										Back
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
}

export default withRouter(Product);
