import React from 'react';
import { connect } from 'react-redux';
import { Button, Grid, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import { Print } from '@material-ui/icons';
import Config from '../../config';
// import { makeStyles } from '@material-ui/core/styles';
import { cartRemove } from '../../redux/actions';
const mapStateToProps = state => ({
	cart: state.cart
});
const mapDispatchToProps = dispatch => ({
	cartRemove: product => dispatch(cartRemove(product))
});
// const useStyles = makeStyles(theme => ({
//
// }));

function OrderComplete(props) {
	// const classes = useStyles();
	const { cart, order, cartRemove, cartRetain } = props;
	console.log(order);
	const { data: { orderID }, details: { status } } = order;
	const [ purchase_units ] = order.details.purchase_units;
	const { amount: { breakdown, value: total }, description } = purchase_units;
	// Empty the cart
	if (!cartRetain) cart.forEach(product => cartRemove(product));
	return (
		<>
			<Grid container>
				<Grid item xs>
					<Typography variant="h2" paragraph>Order Complete - Thank you</Typography>
					<Typography variant="body1" paragraph>Please print this page for your records. If you have any queries please email me at <a href={ `mailto:${ Config.email }` }>{ Config.email }</a>.</Typography>
				</Grid>
				<Grid>
					<Button onClick={ window.print } variant="contained">print <Print /></Button>
				</Grid>
				<Grid item xs={ 12 }>
					<Table size="small">
						<TableBody>
							<TableRow>
								<TableCell>Order Reference</TableCell>
								<TableCell>{ orderID }</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Details</TableCell>
								<TableCell>{ description }</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Status</TableCell>
								<TableCell>{ status }</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</Grid>
				<Grid item md={ 8 } />
				<Grid item md={ 4 }>
					<Table size="small">
						<TableBody>
							{ breakdown && <TableRow>
								<TableCell align="right">Item Total</TableCell>
								<TableCell align="right">{ breakdown.item_total.value }</TableCell>
							</TableRow> }
							{ breakdown && <TableRow>
								<TableCell align="right">Shipping</TableCell>
								<TableCell align="right">{ breakdown.shipping.value }</TableCell>
							</TableRow> }
							<TableRow>
								<TableCell align="right"><Typography variant="h3">Total</Typography></TableCell>
								<TableCell align="right"><Typography variant="h3">${ total }</Typography></TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</Grid>
			</Grid>
		</>
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderComplete);
