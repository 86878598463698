import React from 'react';
import { Typography } from '@material-ui/core';

function Terms () {
	return (
		<>
			<Typography variant="h2" paragraph={true}>Terms and Conditions</Typography>
			<Typography variant="h3" paragraph={true}>Refund Policy (Online Purchases)</Typography>
			<Typography paragraph={true}>Our policy lasts 30 days. If 30 days have gone by since your purchase, unfortunately we can’t offer you a refund or exchange.</Typography>
			<Typography paragraph={true}>To be eligible for a return, your item must be unused and in the same condition that you received it. It must also be in the original packaging.</Typography>
			<Typography paragraph={true}>Additional non-returnable items:</Typography>
			<ul>
				<li>Gift cards</li>
			</ul>
			<Typography paragraph={true}>To complete your return, we require a receipt or proof of purchase.</Typography>
			<Typography paragraph={true}>There are certain situations where only partial refunds are granted: (if applicable)</Typography>
			<ul>
				<li>An item with obvious signs of use</li>
				<li>Any item not in its original condition, is damaged or missing parts for reasons not due to our error.</li>
				<li>Any item that is returned more than 30 days after delivery</li>
			</ul>
			<Typography variant="h4" paragraph={true}>Refunds (if applicable)</Typography>
			<Typography paragraph={true}>Once your return is received and inspected, we will send you an email to notify you that we have received your returned item. We will also notify you of the approval or rejection of your refund. If you are approved, then your refund will be processed, and a credit will automatically be applied to your credit card or original method of payment, within a certain amount of days.</Typography>
			<Typography variant="h4" paragraph={true}>Late or missing refunds (if applicable)</Typography>
			<Typography paragraph={true}>If you haven’t received a refund yet, first check your bank account again.</Typography>
			<Typography paragraph={true}>Then contact your credit card company, it may take some time before your refund is officially posted.</Typography>
			<Typography paragraph={true}>Next contact your bank. There is often some processing time before a refund is posted. If you've done all of this and you still have not received your refund yet, please contact us at mark@markoliverbrawn.com.</Typography>
			<Typography variant="h4" paragraph={true}>Sale items (if applicable)</Typography>
			<Typography paragraph={true}>Only regular priced items may be refunded, unfortunately sale items cannot be refunded.</Typography>
			<Typography variant="h4" paragraph={true}>Exchanges (if applicable)</Typography>
			<Typography paragraph={true}>We only replace items if they are defective or damaged. If you need to exchange it for the same item, send us an email and send your item to: 9 Pioneer Crescent, Putaruru, N, 3415, New Zealand.</Typography>
			<Typography variant="h4" paragraph={true}>Gifts</Typography>
			<Typography paragraph={true}>If the item was marked as a gift when purchased and shipped directly to you, you’ll receive a gift credit for the value of your return. Once the returned item is received, a gift certificate will be mailed to you.</Typography>
			<Typography paragraph={true}>If the item wasn’t marked as a gift when purchased, or the gift giver had the order shipped to themselves to give to you later, we will send a refund to the gift giver and he will find out about your return.</Typography>
			<Typography variant="h3" paragraph={true}>Shipping</Typography>
			<Typography paragraph={true}>To return your product, you should mail your product to: 9 Pioneer Crescent, Arapuni, 3415, New Zealand.</Typography>
			<Typography paragraph={true}>You will be responsible for paying for your own shipping costs for returning your item. Shipping costs are non-refundable. If you receive a refund, the cost of return shipping will be deducted from your refund.</Typography>
			<Typography paragraph={true}>Depending on where you live, the time it may take for your exchanged product to reach you, may vary.</Typography>
			<Typography paragraph={true}>If you are shipping an item over $75, you should consider using a trackable shipping service or purchasing shipping insurance. We don’t guarantee that we will receive your returned item.</Typography>
			<Typography variant="h3" paragraph={true}>PRIVACY STATEMENT</Typography>
			<Typography variant="h4" paragraph={true}>WHAT DO WE DO WITH YOUR INFORMATION?</Typography>
			<Typography paragraph={true}>When you purchase something from our store, as part of the buying and selling process, we collect the personal information you give us such as your name, address and email address.</Typography>
			<Typography paragraph={true}>When you browse our store, we also automatically receive your computer’s internet protocol (IP) address in order to provide us with information that helps us learn about your browser and operating system.</Typography>
			<Typography paragraph={true}>Email marketing (if applicable): With your permission, we may send you emails about our store, new products and other updates.</Typography>
			<Typography variant="h4" paragraph={true}>CONSENT</Typography>
			<Typography paragraph={true}>How do you get my consent?</Typography>
			<Typography paragraph={true}>When you provide us with personal information to complete a transaction, verify your credit card, place an order, arrange for a delivery or return a purchase, we imply that you consent to our collecting it and using it for that specific reason only.</Typography>
			<Typography paragraph={true}>If we ask for your personal information for a secondary reason, like marketing, we will either ask you directly for your expressed consent, or provide you with an opportunity to say no.</Typography>
			<Typography paragraph={true}><em><strong>How do I withdraw my consent?</strong></em><br/><br/>If after you opt-in, you change your mind, you may withdraw your consent for us to contact you, for the continued collection, use or disclosure of your information, at anytime, by contacting us at info@somewhereintime.nz</Typography>
			<Typography variant="h4" paragraph={true}>DISCLOSURE</Typography>
			<Typography paragraph={true}>We may disclose your personal information if we are required by law to do so or if you violate our Terms of Service.</Typography>
			<Typography variant="h4" paragraph={true}>THIRD-PARTY SERVICES</Typography>
			<Typography paragraph={true}>In general, the third-party providers used by us will only collect, use and disclose your information to the extent necessary to allow them to perform the services they provide to us.</Typography>
			<Typography paragraph={true}>However, certain third-party service providers, such as payment gateways and other payment transaction processors, have their own privacy policies in respect to the information we are required to provide to them for your purchase-related transactions.</Typography>
			<Typography paragraph={true}>For these providers, we recommend that you read their privacy policies so you can understand the manner in which your personal information will be handled by these providers.</Typography>
			<Typography paragraph={true}>In particular, remember that certain providers may be located in or have facilities that are located in a different jurisdiction than either you or us. So if you elect to proceed with a transaction that involves the services of a third-party service provider, then your information may become subject to the laws of the jurisdiction(s) in which that service provider or its facilities are located.</Typography>
			<Typography paragraph={true}>As an example, if you are located in Canada and your transaction is processed by a payment gateway located in the United States, then your personal information used in completing that transaction may be subject to disclosure under United States legislation, including the Patriot Act.</Typography>
			<Typography paragraph={true}>Once you leave our store’s website or are redirected to a third-party website or application, you are no longer governed by this Privacy Policy or our website’s Terms of Service.</Typography>
			<Typography variant="h5" paragraph={true}>Links</Typography>
			<Typography paragraph={true}>When you click on links on our store, they may direct you away from our site. We are not responsible for the privacy practices of other sites and encourage you to read their privacy statements.</Typography>
			<Typography variant="h4" paragraph={true}>SECURITY</Typography>
			<Typography paragraph={true}>To protect your personal information, we take reasonable precautions and follow industry best practices to make sure it is not inappropriately lost, misused, accessed, disclosed, altered or destroyed.</Typography>
			<Typography variant="h5" paragraph={true}>COOKIES</Typography>
			<Typography paragraph={true}>Here is a list of cookies that we use. We’ve listed them here so you can choose if you want to opt-out of cookies or not.</Typography>
			<Typography paragraph={true}>cart, unique token, persistent, stores information about the contents of your cart.</Typography>
			<Typography variant="h4" paragraph={true}>AGE OF CONSENT</Typography>
			<Typography paragraph={true}>By using this site, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.</Typography>
			<Typography variant="h4" paragraph={true}>CHANGES TO THIS PRIVACY POLICY</Typography>
			<Typography paragraph={true}>We reserve the right to modify this privacy policy at any time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on the website. If we make material changes to this policy, we will notify you here that it has been updated, so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we use and/or disclose it.</Typography>
			<Typography paragraph={true}>If our store is acquired or merged with another company, your information may be transferred to the new owners so that we may continue to sell products to you.</Typography>
			<Typography variant="h5" paragraph={true}>QUESTIONS AND CONTACT INFORMATION</Typography>
			<Typography paragraph={true}>If you would like to: access, correct, amend or delete any personal information we have about you, register a complaint, or simply want more information contact us at mandy@somewherintime.co.nz</Typography>
		</>
	);
}
export default Terms;
