import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Button, Card, CardActionArea, CardHeader, CardMedia, Grid  } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ImageComponent } from './';
import { uniqueValues } from '../../includes/lib';

const useStyles = makeStyles(theme => ({
	button: {
		[ theme.breakpoints.up('lg') ]: {
			display: 'none'
		}
	},
	media: {
		height: 0,
		paddingTop: '100%', // '56.25%', // 16:9
	},
	section: {
		marginBottom: theme.spacing(2)
	},
	title: {
		marginBottom: theme.spacing(2)
	},
	hideOnMd: {
		[ theme.breakpoints.down('md') ]: {
			display: 'none'
		}
	},
	hideOnSm: {
		[ theme.breakpoints.down('sm') ]: {
			display: 'none'
		}
	}
}));

function Categories(props) {
	const [ showingAll, setShowingAll ] = useState();
	const classes = useStyles();
	const { data } = props;
	const categories = uniqueValues(data.map(i => i.mainCategory));
	return (
		<>
			<Grid container className={ classes.section } spacing={ 2 }>
				{ categories.map((category, index) => {
					const link = `/category/${ category.slug }`;
					const image = data.filter(p => p.mainCategory.slug === category.slug)[ 0 ].thumbnail;
					return (
						<Grid item xs={ 12 } sm={ 6 } md={ 4 } lg={ 4 } key={ `${ category.slug }-${ Math.random() }` } className={ index >= 4 && !showingAll ? classes.hideOnMd : index >= 2 && !showingAll ? classes.hideOnSm : null }>
							<Card className={ classes.card }>
								<CardActionArea component={ Link } to={ link }>
									<CardMedia className={classes.media} component={ (props) => <ImageComponent src={ image } className={ classes.media } /> } image={ image } title={ category.name } />
									<CardHeader className={classes.cardHeader} title={ category.name } />
								</CardActionArea>
							</Card>
						</Grid>
					);
				} ) }
			</Grid>
			<Button className={ classes.button } onClick={ () => setShowingAll(!showingAll) }>Show All Categories</Button>
		</>
	);
}

export default withRouter(Categories);
