import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import teal from '@material-ui/core/colors/teal';
import { Card, CardContent, CardHeader, CardMedia, Grid } from '@material-ui/core';
import { GoogleMap, OpeningHours } from '../widgets';
import Config from '../../config';

const useStyles = makeStyles(theme => ({
	media: {
		// border: `5px solid ${ teal[ 500 ] }`,
		// borderRadius: '50%',
		// boxShadow: '0px 0px 50px #000',
		// boxShadow: '0px 0px 50px #1a3c3e inset, 0px 0px 25px #1e3f42 inset, 0px 0px 10px #193a3d inset',
		// filter: 'sepia() hue-rotate(130deg) brightness(0.8) contrast(1.75)',
		// opacity: 0.5
	},
	mapLoading: {
		height: `100%`,
		[theme.breakpoints.down('sm')]: {
			height: '50vmin'
		}
	},
	mapContainer: {
		height: `100%`,
		opacity: 0.8,
		[theme.breakpoints.down('sm')]: {
			height: '50vmin'
		}
	},
	mapElement: {
		height: `100%`
	}
}));

export default (props) => {
	const classes = useStyles();
	const { google: { map: { key, lat, lng, zoom } } } = Config;
	return (
		<Grid container spacing={ 2 }>
			<Grid item xs={ 12 } md={ 8 }>
				<GoogleMap
					zoom={ zoom }
					centre={ { lat, lng } }
					googleMapURL={ `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${ key }` }
					loadingElement={<div className={ classes.mapLoading } />}
					containerElement={<div className={ classes.mapContainer } />}
					mapElement={<div className={ classes.mapElement } />}
				/>
			</Grid>
			<Grid item md={ 4 }>
				<Card>
					<CardHeader title="Visit the Craft Shop" />
					<CardMedia
						className={ classes.media }
						component="img"
						alt="The shop"
						height="250"
						image="img/shop_1.jpg"
						title="The shop"
					/>
					<CardContent>
						<OpeningHours titleVariant="h6" showAddress />
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};
