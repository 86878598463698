import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, /* CircularProgress, */ Grid, Typography, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { ArrowBack as BackIcon, Info as InfoIcon, Warning as WarningIcon } from '@material-ui/icons';
import { Helmet } from 'react-helmet';
import { ImageComponent, ProductButton } from '../widgets';
import { PayPalButton } from "react-paypal-button-v2";
import { OrderComplete } from './';
import Config from '../../config';
// import Cogs from './widgets/cogs';
import { makeStyles /* , useTheme */ } from '@material-ui/core/styles';
import { cartRemove } from '../../redux/actions';

const mapStateToProps = state => ({
	cart: state.cart
});
const mapDispatchToProps = dispatch => ({
	cartRemove: product => dispatch(cartRemove(product))
});
const useStyles = makeStyles(theme => ({
	th: {
		background: 'none'
	},
	title: {
		marginBottom: theme.spacing(2)
	},
	label: {
		display: 'inline-block',
		padding: theme.spacing(1),
		textTransform: 'uppercase'
	},
	media: {
		// backgroundSize: 'contain!important',
		height: '10vmin',
		paddingTop: '0!important',
		width: '10vmin',
		[theme.breakpoints.up('md')]: { paddingTop: '56.25%' },
	},
	mediaCell: {
		background: 'none',
		width: '10vmin'
	},
	leftIcon: {
		margin: theme.spacing(0, 1, -0.5, 0)
	},
	rightIcon: {
		marginLeft: theme.spacing(1)
	},
	stageMessage: {
		borderColor: theme.palette.error,
		borderStyle: 'dashed',
		borderWidth: 1,
		padding: theme.spacing(1)
	},
	unstyledLink: {
		color: 'inherit',
		textDecoration: 'none'
	}
}));

function Cart(props) {
	const [ state, setState ] = useState({ order: null, busy: false});
	const classes = useStyles();
	// const theme = useTheme();
	// console.log(theme);
	const { cart, history, products } = props;
	const cartProducts = cart.map(id => products.filter(p => p.id === id)[ 0 ]).filter(p => p);
	const total = cartProducts.reduce((accumulator, item) => accumulator + item.price, 0).toFixed(2);
	const orderDescription = cartProducts.map(product => `${ product.name }: ${ product.price }`).join(', ');
	const order = {
		intent: 'CAPTURE',
		purchase_units: [{
			description: orderDescription,
			amount: {
				breakdown: {
					item_total: {
						currency_code: Config.currency,
						value: total
					},
					shipping: {
						currency_code: Config.currency,
						value: Config.standardShipping.toFixed(2)
					},
					tax_total: {
						currency_code: Config.currency,
						value: 0
					},
					discount: {
						currency_code: Config.currency,
						value: 0}
				},
				currency_code: Config.currency,
				value: ((+total) + (+Config.standardShipping)).toFixed(2)
			}
		}]
	};
	const createOrder = (data, actions) => actions.order.create(order);

	// if (state.busy) {
	// return (
	// 	<Cogs modal color={ `${ theme.palette.primary.main }50` }/>
	// );
	// }

	if (state.order) return <OrderComplete order={ state.order } cartRetain={ process.env.NODE_ENV !== 'production' } />;

	return (
		<>
			<Helmet>
				<title>Shopping Cart :: Somewhere in Time</title>
			</Helmet>
			<Grid container className={ classes.section }>
				<Grid item xs>
					<Typography className={ classes.title } variant="h2" paragraph>Your Cart</Typography>
				</Grid>
				<Grid item>
					<Button aria-label="more" onClick={ () => history.goBack() }>
						<BackIcon className={ classes.leftIcon } />
						Back
					</Button>
				</Grid>
			</Grid>
			{ cart.length === 0 && <Typography className={ classes.title } variant="body1" paragraph>Your cart is empty</Typography> }
			{ cart.length > 0 && (
				<Table className={classes.table} stickyHeader size="small">
					<TableHead>
						<TableRow>
							<TableCell className={ classes.mediaCell }></TableCell>
							<TableCell className={ classes.th }>Item</TableCell>
							<TableCell className={ classes.th } align="right">Price (NZD)</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{ cartProducts.map((item) => (
							<TableRow key={ item.id }>
								<TableCell component="th" scope="row">
									<Link to={ `/product/${ item.slug }` }>
										<ImageComponent src={ item.image } className={ classes.media } />
									</Link>
								</TableCell>
								<TableCell>
									<Link to={ `/product/${ item.slug }` } className={ classes.unstyledLink }>
										<Typography variant="h3" className={ classes.sitename } paragraph>
											{ item.name }
										</Typography>
									</Link>
									<ProductButton data={ item } />
								</TableCell>
								<TableCell align="right">
									<Typography variant="h4" className={ classes.sitename }>
										${ item.price.toFixed(2) }
									</Typography>
								</TableCell>
							</TableRow>
						)) }
						<TableRow>
							<TableCell align="right" colSpan="3">
								<Typography variant="h2" paragraph>
									Total: { `$${ total }` }
								</Typography>
								<Typography variant="body1" paragraph>
									Shipping: { `New Zealand and Australia: FREE. Rest of the World: $${ Config.standardShipping.toFixed() }` }
								</Typography>
								<center style={ { margin: '5vmin' } }>
									{ process.env.NODE_ENV !== 'production' && (
										<Typography color="error" variant="body1" paragraph className={ classes.stageMessage }>
											<WarningIcon color="error" className={ classes.leftIcon } />
											{ `Running in ${ process.env.NODE_ENV } mode` }
										</Typography>
									) }
									<Typography color="secondary" variant="body1" paragraph>
										<InfoIcon color="secondary" className={ classes.leftIcon } />
										Please do not refresh this page after paying. You will be forwarded to the order complete page automatically.
									</Typography>
									<PayPalButton
										amount={ total }
										currency={ Config.currency }
										createOrder={ createOrder }
										onShippingChange={
											(data, actions) => {
												setState({
													busy: true
												});
												try {
													const { shipping_address: { country_code } } = data;
													// Reject non-US addresses example
													// if (data.shipping_address.country_code !== 'US') {
													// 	return actions.reject();
													// }
													console.log('onShippingChange', data, actions, country_code);
													if (Config.zeroShippingCountries.includes(country_code)) {
														console.log(`${ country_code } is eligible for free shipping`);
														return actions.order.patch([{
															op: 'replace',
															path: '/purchase_units/@reference_id==\'default\'/amount',
															value: {
																currency_code: Config.currency,
																value: (+total).toFixed(2),
																breakdown: {
																	item_total: {
																		currency_code: Config.currency,
																		value: total
																	},
																	shipping: {
																		currency_code: Config.currency,
																		value: "0.00"
																	}
																}
															}
														}]);
													}
												} catch (e) {
													console.log('Error updating shipping', e);
												}
												// actions.order.breakdown = {
												// 	item_total: {currency_code: Config.currency, value: total},
												// 	shipping: {currency_code: Config.currency, value: Config.standardShipping.toFixed(2)},
												// 	tax_total: {currency_code: Config.currency, value: 0},
												// 	discount: {currency_code: Config.currency, value: 0}
												// };
												// actions.order.patch(actions.order);
											}
										}
										onSuccess={ (details, data) => {
											setState({
												order: { details, data },
												busy: false
											});
											// OPTIONAL: Call your server to save the transaction
											// return fetch("/paypal-transaction-complete", {
												// 	method: "post",
												// 	body: JSON.stringify({
													// 		orderID: data.orderID
													// 	})
													// });
										} }
										options={ {
											clientId: Config.paypalClientId[ process.env.NODE_ENV ],
											currency: Config.currency
										} }
										style={ {
											color: 'black',
											label: 'pay',
											tagline: false
										} }
									/>
								</center>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			) }
		</>
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Cart));
