import React from 'react';
import { CircularProgress } from '@material-ui/core';

function Cogs(props) {
	// const { style } = props;
	return <div style={ {
		left: '50%',
		position: 'fixed',
		top: '50%',
		transform: 'translate3D(-50%,-50%,0)'
	} }>
		<CircularProgress size="10vmin" />
	</div>;
};

// // import { Settings } from '@material-ui/icons';
// import { makeStyles, useTheme } from '@material-ui/core/styles';
//
// const useStyles = makeStyles(theme => ({
// 	cogContainer: {
// 		left: '50%',
// 		position: 'absolute',
// 		top: '50%',
// 		transform: 'translate3D(-50%, -50%, 0)'
// 	},
// 	cogBody: {
// 		border: '7px solid transparent',
// 		borderRadius: '50%',
// 		// boxShadow: 'inset 0 0 10px #000',
// 		left: '50%',
// 		position: 'absolute',
// 		top: '50%',
// 		transform: 'translate3D(-50%, -50%, 0)'
// 	},
// 	cogCentre: {
// 		border: '7px solid transparent',
// 		borderRadius: '50%',
// 		// boxShadow: 'inset 0 0 10px #000',
// 		height: '50%',
// 		left: '50%',
// 		position: 'absolute',
// 		top: '50%',
// 		transform: 'translate3D(-50%, -50%, 0)',
// 		width: '50%'
// 	},
// 	cogSpin: {
// 		left: '50%',
// 		position: 'absolute',
// 		top: '50%',
// 		transform: 'translate3D(-50%, -50%, 0)'
// 	},
// 	tooth: {
// 		// boxShadow: 'inset 0 0 10px #000',
// 		left: '50%',
// 		position: 'absolute',
// 		top: '50%',
// 		width: '100%'
// 	}
// }));
//
// function Cogs(props) {
// 	const classes = useStyles();
// 	const theme = useTheme();
// 	console.log(theme);
// 	const { height, width } = props;
// 	const makeCog = ({ color, direction, left, numTeeth, size, speed, toothLength, toothWidth, top }) => {
// 		const radius = size / 2;
// 		const circumference = 2 * Math.PI * ( radius - toothLength );
// 		numTeeth = numTeeth || Math.floor(circumference / ( toothWidth ) / 3);
// 		// toothWidth = toothWidth || circumference / numTeeth / 3;
// 		const teeth = [];
// 		for (let i = 1; i <= numTeeth; i++) {
// 			teeth.push({
// 				key: `cog-${ i }`,
// 				rotation: 360 * (i / numTeeth / 2)
// 			});
// 		}
// 		return (
// 			<div className={ classes.cogSpin } key={ Math.random() } style={ { animation: `spin ${speed}s linear infinite ${direction}`, left, top } }>
// 				<div className={ classes.cogContainer } style={ { height: size, width: size } }>
// 					{ teeth.map((tooth) => (
// 						<div key={ tooth.key } className={ classes.tooth } style={ { background: color.main, height: toothWidth, transform: `translate3D(-50%, -50%, 0) rotate(${ tooth.rotation }deg)` } } />
// 					)) }
// 					<div className={ classes.cogBody } style={ { background: color.light, borderColor: color.main, height: `calc(100% - ${ toothLength }px)`, width: `calc(100% - ${ toothLength }px)` } }>
// 						<div className={ classes.cogCentre } style={ { background: color.main, borderColor: color.dark }} />
// 					</div>
// 				</div>
// 			</div>
// 		);
// 	};
// 	const bigCogRadius = 100;
// 	const toothWidth = 20;
// 	return (
// 		<div className={ classes.container } style={ {
// 			border: '1px solid red',
// 			height: height || 500,
// 			left: '50%',
// 			// overflow: 'hidden',
// 			position: 'fixed',
// 			top: '50%',
// 			transform: 'translate3D(-50%, -50%, 0)',
// 			width: width || 500,
// 			zIndex: 1000
// 		} }>
// 			{ [{
// 				color: theme.palette.primary, //'#bfccca',
// 				direction: 'reverse',
// 				left: 375,
// 				size: bigCogRadius * 2,
// 				speed: 10,
// 				toothLength: 40,
// 				numTeeth: 10,
// 				top: 132
// 			},{
// 				color: theme.palette.primary, //'#a8bdba',
// 				direction: 'normal',
// 				left: 245,
// 				size: 150,
// 				speed: 7,
// 				toothLength: 40,
// 				numTeeth: 10,
// 				top: 234
// 			},
// 			// {
// 			// 	color: '#5f8a84',
// 			// 	direction: 'reverse',
// 			// 	left: '66px',
// 			// 	size: '150px',
// 			// 	speed: '2s',
// 			// 	top: '20%'
// 			// },{
// 			// 	color: '#809c98',
// 			// 	direction: 'normal',
// 			// 	left: '-50px',
// 			// 	size: '200px',
// 			// 	speed: '2.5s',
// 			// 	top: '50%'
// 			// }
// 			].map((cog, index) => {
// 				return (
// 					makeCog(cog)
// 				);
// 				// return (
// 				//
// 				// 	<Settings key={ Math.random() } style={ {
// 				// 		animationDirection: cog.direction,
// 				// 		animationDuration: cog.speed,
// 				// 		animationIterationCount: 'infinite',
// 				// 		animationName: 'rotation',
// 				// 		animationTimingFunction: 'linear',
// 				// 		color: cog.color,
// 				// 		// float: 'left',
// 				// 		fontSize: cog.size,
// 				// 		left: cog.left,
// 				// 		// margin: '-5%',
// 				// 		position: 'absolute',
// 				// 		top: cog.top
// 				// 	} } />
// 				// )
// 			}) }
// 		</div>
// 	);
// }
//
export default Cogs;
