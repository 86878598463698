import moment from 'moment';
import { encode } from '../includes/lib';
import Config from '../config';

class Product {
	constructor(props) {
		this._data = props;
	}
	get description() {
		return (this._data.Description || 'No description yet...').trim();
	}
	get displayOnHome() {
		return this._data['Display on Homepage'].toLowerCase() === 'yes';
	}
	get height() {
		return this._data.Height;
	}
	get id() {
		return this._data.ID;
	}
	get image() {
		return this._data.Image;
	}
	get isSold() {
		return this._data.Sold.toLowerCase() === 'yes';
	}
	get mainCategory() {
		return {
			name: this._data['Main Category'],
			slug: encode(this._data['Main Category'])
		};
	}
	get name() {
		return this._data.Name;
	}
	get price() {
		return +(this._data.Price.replace('$', ''));
	}
	get slug() {
		return encode(this.name);
	}
	get structuredData() {
		// console.log(window.location);
		return {
			"@context": "https://schema.org/",
			"@type": "Product",
			"name": this.name,
			"description": this.description,
			"url": `${ Config.liveDomain }/product/${ this.slug }`,
			"sku": this.id,
			// offers: ///
			// "priceValidUntil": moment().add(1, 'year').format('YYYY-MM-DD'),
			"brand": "Somewhere in Time",
			"mpn": this.id,
			"image": this.image.match(/^\/img/) ? `${ Config.liveDomain }${this.image}` : this.image,
			// "aggregateRating": {"@type": "AggregateRating", "ratingValue": 5, "reviewCount": 1},
			"offers": {
				"@type": "Offer",
				"url": "https://example.com/anvil",
				"priceCurrency": "NZD",
				"price": this.price,
				"priceValidUntil": moment().add(1, 'year').format('YYYY-MM-DD'),
				"itemCondition": "https://schema.org/NewCondition",
				"availability": `https://schema.org/${ this.isSold ? 'SoldOut' : 'InStock' }`,
				"seller": {
					"@type": "Organization",
					"name": Config.appName
				}
			}
		};
	}
	get thumbnail() {
		return this.image;
		// if(!this.image.match(/^\/img/)) return this.image.replace(/\.([jpg|jpeg])/, '_500x.$1');
		// else return this.image;
	}
	get width() {
		return this._data.Width;
	}
}

export default Product;
