import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { AppBar as MuiAppBar, Badge, Button, Container, Grid, Menu, MenuItem, Slide, Toolbar, useScrollTrigger } from '@material-ui/core';
import { ShoppingCart as CartIcon } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { uniqueValues } from '../../includes/lib';
import { cartAdd, cartRemove } from '../../redux/actions';
import logo from '../../banner.png';

const mapStateToProps = state => ({
	cart: state.cart
});
const mapDispatchToProps = dispatch => ({
	cartAdd: product => dispatch(cartAdd(product)),
	cartRemove: product => dispatch(cartRemove(product))
});
const styles = theme => ({
	appBar: {
		background: 'none',
		boxShadow: 'none'
	},
	appBarMini: {
		background: `${ theme.palette.primary.main }fc`
	},
	iconLeft: {
		marginRight: theme.spacing(1)
	},
	iconRight: {
		marginLeft: theme.spacing(1)
	},
	logo: {
		maxWidth: '90%'
	},
	logoMini: {
		height: theme.spacing(8),
		maxWidth: '90%'
	},
	toolbar: {
		minHeight: 80
	},
	grow: {
		flexGrow: 1,
	},
	paper: {
		marginTop: theme.spacing(2),
		padding: theme.spacing(2)
	},
	sitenameLink: {
		color: 'inherit',
		display: 'block',
		padding: theme.spacing(1, 0),
		textDecoration: 'none'
	},
	sitenameItem: {
		textAlign: 'left',
		[theme.breakpoints.down('md')]: { textAlign: 'center' }
	},
	menu: {
		background: theme.palette.primary.main
	},
	navlinksItem: {
		alignItems: 'right',
		alignSelf: 'center',
		textAlign: 'right',
		[theme.breakpoints.down('md')]: {
			alignItems: 'center',
			textAlign: 'center'
		}
	}
});

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={ false } direction="down" in={ !trigger }>
      { children }
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

function AppBar(props) {
	const { cart, classes, data } = props;
	const [ anchorEl, setAnchorEl ] = useState(null);
	const [ miniBar, setMiniBar ] = useState(false);
	const handleClick = (event) => setAnchorEl(event.currentTarget);
	const handleClose = () => setAnchorEl(null);
	const getProductCount = category => data.filter(i => i.mainCategory.slug === category.slug).length;
	window.onscroll = (event) => {
		// console.log(window.scrollY);
		setMiniBar(window.scrollY > 150);
	};
	return (
		<HideOnScroll { ...props }>
			<MuiAppBar className={ miniBar ? classes.appBarMini : classes.appBar }>
				<Container maxWidth={ miniBar ? null : "lg" }>
					<Toolbar className={ classes.toolbar }>
						<Grid container>
							<Grid item xs={ 12 } lg={ 6 } className={ classes.sitenameItem }>
								<Link to ="/" className={ classes.sitenameLink}>
									<img src={ logo } alt="Somewhere in Time logo" className={ miniBar ? classes.logoMini : classes.logo } />
								</Link>
							</Grid>
							<Grid item xs={ 12 } lg={ 6 } className={ classes.navlinksItem }>
								<Button color="inherit" component={ Link } to="/">Home</Button>
								<Button color="inherit" component={ Link } to="/visit">Visit</Button>
								{ data && <Button color="inherit" aria-controls="menu" aria-haspopup="true" onClick={handleClick}>Categories</Button> }
								<Button color="inherit" component={ Link } to="/terms">Terms</Button>
								{ cart && cart.length > 0 && (
									<Button color="inherit" component={ Link } to="/cart">
										<Badge className={ classes.padding } color="secondary" badgeContent={ cart.length }>
											<CartIcon />
										</Badge>
									</Button>
								) }
								{ data && (
									<Menu id="menu" anchorEl={ anchorEl } keepMounted open={ Boolean(anchorEl) } onClose={ handleClose } classes={ { paper: classes.menu } }>
										{ uniqueValues(data.map(i => i.mainCategory)).map(category => (
											<MenuItem key={ category.slug } component={ Link } to={`/category/${ category.slug }`} onClick={ handleClose }>
												{ category.name } ({ getProductCount(category) })
											</MenuItem>
										)) }
									</Menu>
								) }
							</Grid>
						</Grid>
					</Toolbar>
				</Container>
			</MuiAppBar>
		</HideOnScroll>
	);
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AppBar));
