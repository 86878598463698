import React from 'react';
import Product from './product.js';
import Grid from '@material-ui/core/Grid';

function Products(props) {
	const { data } = props;

	return (
		<Grid container spacing={ 2 } padding={ 2 }>
			{
				data.map((item) => (
					<Grid item xs={ 12 } sm={ 6 } md={ 4 } lg={ 3 } key={ item.id }>
						<Product data={ item } />
					</Grid>
				))
			}
		</Grid>
	);
}

export default Products;
