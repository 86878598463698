import React from 'react';
import { Button  } from '@material-ui/core';

function BuyButton(props) {
	const { data } = props;
	const isSold = data.Sold.toLowerCase() === 'yes';
	if (!data.Link) return <Button disabled variant="contained" color="secondary">Coming Soon...</Button>
	return (
		<Button disabled={ isSold } variant="contained" color="secondary" href={ data.Link } target="_blank">{ isSold ? 'Sold' : 'Buy Now' }</Button>
	);
}

export default BuyButton;
