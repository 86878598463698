import React from 'react';
import { withRouter } from 'react-router-dom';
// import { makeStyles } from '@material-ui/core/styles';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

// const useStyles = makeStyles(theme => ({
// 	hero: {
// 		background: 'url("img/banner.png") no-repeat',
// 		backgroundSize: 'contain',
// 		margin: theme.spacing(-2, -2, 2, -2),
// 		paddingTop: '38.25%'
// 	}
// }));

function Hero(props) {
	const { history: { location: { pathname } }, data } = props;
	// // const classes = useStyles();
	// console.log(props);
	// return (
	// 	<>
	// 		{ false && pathname === '/' && <div className={ classes.hero } /> }
	// 	</>
	// );
	return  (
		<>
			{ false && pathname === '/' && (
				<Carousel showThumbs={ false }>
					{ data.map(({ image, title }) => (
						<div>
							<img src={ image } alt={ title } style={ { transform: 'translate3D(0, -50%, 0)' } } />
							{ /* <p className="legend">{ title }</p> */ }
						</div>
					)) }
				</Carousel>
			) }
		</>
  );
}

export default withRouter(Hero);
