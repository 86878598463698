import React from 'react';
import teal from '@material-ui/core/colors/teal';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Typography  } from '@material-ui/core';
import { Categories, ImageComponent, /* LadiesAndGents, */ OpeningHours, Products } from '../widgets';
import { Link } from 'react-router-dom';
// import Hero from './widgets/hero.js';
import { randomElements } from '../../includes/lib';
const useStyles = makeStyles(theme => ({
	title: {
		margin: theme.spacing(2, 0, 4, 0)
	},
	title3: {
		color: teal[300],
		margin: theme.spacing(2, 0, 4, 0)
	},
	openingPreamble: {
		color: teal[100]
	},
	media: {
		border: `5px solid ${ teal[ 500 ] }`,
		borderRadius: '50%',
		boxShadow: '0px 0px 50px #000',
		// boxShadow: '0px 0px 50px #1a3c3e inset, 0px 0px 25px #1e3f42 inset, 0px 0px 10px #193a3d inset',
		filter: 'sepia() hue-rotate(130deg) brightness(0.8) contrast(1.75)',
		opacity: 0.5
	}
}));
function Home(props) {
	const classes = useStyles();
	const { products } = props;
	return (
		<Grid container spacing={ 4 }>
			<Grid item md={ 9 }>
				{ /* <Hero /> */ }
				<Typography className={ classes.title } variant="h2">Explore</Typography>
				<Categories data={ products } />
				<Typography className={ classes.title } variant="h2">Featured</Typography>
				<Products data={ randomElements(products.filter(product => product.displayOnHome ), 4) } />
				{ /* <Typography className={ classes.title } variant="h2">About</Typography>
				<ProductsGrid data={ randomElements(products, 10) } /> */ }
			</Grid>
			<Grid item md={ 3 }>
				{ /* <LadiesAndGents /> */ }
				<Typography className={ classes.title } variant="h2">Craft Shop Now Open</Typography>
				<Typography className={ classes.openingPreamble } paragraph>The shop is now open, so if you're passing through Arapuni in the Waikato, New Zealand, why not stop by and say hello..? We're just off the main road.</Typography>
				<Button component={ Link } to="/visit" variant="contained" color="primary">Visit Us...</Button>
				<OpeningHours />
				<ImageComponent src="img/shop_4.jpg"  alt="The Craft Shop" className={ classes.media } />
			</Grid>
		</Grid>
	);
}

export default Home;
