import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import teal from '@material-ui/core/colors/teal';
import { Typography  } from '@material-ui/core';
import Config from '../../config';

const useStyles = makeStyles(theme => ({
	title: {
		color: teal[200],
		margin: theme.spacing(2, 0, 4, 0)
	},
	main: {
		color: teal[200]
	}
}));
export default (props) => {
	const classes = useStyles();
	const { titleVariant, showAddress } = props;
	return (
		<>
			{ showAddress && <Typography className={ classes.address } variant={ 'address' } paragraph>{ Config.address.split(',').map(line => (<>{line}<br/></>)) }</Typography> }
			<Typography className={ classes.title } variant={ titleVariant || 'h2' }>Opening Hours</Typography>
			<Typography className={ classes.main } paragraph>Monday: 10am - 2pm<br />Tuesday: 10am - 2pm<br />Wednesday: 12pm - 3pm<br />Thursday: Closed<br />Friday: 10am - 2pm<br />Saturday: 10am - 2pm<br />Sunday: Closed<br/><br/><em style={{ opacity: 0.5 }}>Additional times by appointment.</em></Typography>
		</>
	);
}
