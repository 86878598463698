import { CART_ADD, CART_REMOVE } from './action-types';
import Config from '../config';

const initialState = {
	cart: []
};

// Load from local storage or initialState
const defaultState = JSON.parse(
	localStorage.getItem(`${ Config.appId }-state`)
) || initialState;

const rootReducer = (state = defaultState, action) => {
	console.log(action.type, state);
	switch (action.type) {
		case CART_ADD: {
			// const newPayload = Object.assign({
			// }, action.payload);
			return {
				...state,
				cart: [ ...state.cart, action.payload ]
			};
		}
		case CART_REMOVE:
			console.log(state, action.payload);
			return {
				...state,
				cart: state.cart.filter(item => item !== action.payload)
			};
		default:
			return state;
	}
};

export default rootReducer;
