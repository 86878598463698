import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBack as BackIcon } from '@material-ui/icons';
import { Badge, Card, CardActions, CardActionArea, CardContent, CardHeader, CardMedia, Grid, IconButton, Tooltip } from '@material-ui/core';
import { ImageComponent, ProductButton } from './';
import { truncate } from '../../includes/lib';
import Config from '../../config';

const useStyles = makeStyles(theme => ({
	badge: {
		display: 'block',
	},
	badgeBadge: {
		borderRadius: '0 0 0 50%',
		boxShadow: '-8px 5px 3px 0px rgba(0,0,0,0.2)',
		fontFamily: '\'Glass Antiqua\', cursive', //"'Lora', serif"
		fontSize: '1.1em',
		height: 'auto',
		margin: '1em 1.4em 0px 0px', //theme.spacing(2, 3, 0, 0),
		padding: '0.5em',
		display: 'block'
	},
	cardContent: {
		paddingTop: 0,
		[theme.breakpoints.up('md')]: { minHeight: '10vmin' },
		[theme.breakpoints.up('lg')]: { minHeight: '8vmin' },
		[theme.breakpoints.up('xl')]: { minHeight: '13vmin' }
	},
	cardHeader: {
		paddingBottom: 0
	},
	media: {
		height: 0,
		paddingTop: '100%', // 16:9
		// [theme.breakpoints.up('md')]: { paddingTop: '56.25%' },
		[theme.breakpoints.up('md')]: { paddingTop: '100%' },
	},
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		transform: 'rotate(180deg)',
	}
}));

function Product (props) {
	const classes = useStyles();
	const { data } = props;
	const link = `/product/${ data.slug }`;
	return (
		<Badge badgeContent={ data.isNew ? 'NEW!' : '' } classes={ { badge: classes.badgeBadge } } color="secondary" className={ classes.badge } invisible={ !data.isNew }>
			<Card className={ classes.card }>
				<CardActionArea component={ Link } to={ link }>
					<CardMedia className={classes.media} component={ (props) => <ImageComponent src={ data.thumbnail } className={ classes.media } /> } image={ data.image } title={ data.name } />
					<CardHeader className={classes.cardHeader} title={ data.name } subheader={ data.isSold ? 'Sold' : `$${ data.price.toFixed(2) }` } />
					<CardContent className={classes.cardContent}>
						<Tooltip title={ data.description }>
							<>{ truncate(data.description, Config.maxLengthShortDescription) }</>
						</Tooltip>
					</CardContent>
				</CardActionArea>
				<CardActions>
					<Grid container alignItems="baseline" style={{padding: '8px'}}>
						<Grid item xs>
							<ProductButton data={ data } />
						</Grid>
						<Grid item>
							<IconButton aria-label="more" component={ Link } to={ link }>
								<BackIcon />
							</IconButton>
						</Grid>
					</Grid>
				</CardActions>
			</Card>
		</Badge>
	);
}
export default withRouter(Product);
