import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: '100%',
    height: '100%',
  },
}));

/**
 * The example data is structured as follows:
 *
 * import image from 'path/to/image.jpg';
 * [etc...]
 *
 * const tileData = [
 *   {
 *     img: image,
 *     title: 'Image',
 *     author: 'author',
 *     cols: 2,
 *   },
 *   {
 *     [etc...]
 *   },
 * ];
 */
export default function ImageGridList(props) {
  const classes = useStyles();
  const { data } = props;
  return (
    <div className={ classes.root } {...props}>
      <GridList className={ classes.gridList } cols={ 6 }>
        {data.map(product => (
          <GridListTile key={ product.image } cols={ product.cols || 1 } rows={ product.rows || 1 }>
            <img src={ product.image } alt={ product.name } />
          </GridListTile>
        ))}
      </GridList>
    </div>
  );
}
